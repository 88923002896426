import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Products from '../components/Products'
import { ProductProvider } from '../components/contexts'

const VaccinePage = ({ data, pageContext, location }) => {
  const gatsbyPage = data.wordpressWpGatsbyPage.acf

  const mergedTopSearch = data.wordpressWpGatsbyPage.acf.top_searchs
    .split('\r\n')
    .map((item, index) => {
      const productName = item.split('|')[0]
      const productUrl = item.split('|')[1]
      return `${productName} | ${productUrl}`
    })

  const productsData = {
    type: 'vaccine',
    title: gatsbyPage.subtitle,
    demonstrative_text: gatsbyPage.demonstrative_text,
    top_searchs_title: gatsbyPage.top_searchs_title,
    product_budget: gatsbyPage.product_budget,
    topSearchs: mergedTopSearch,
    showVaccination: gatsbyPage.show_vaccination,
    vaccinationTitle: gatsbyPage.vaccination_title,
    vaccinationInfo: gatsbyPage.vaccination_info,
    products: data.allProducts.nodes,
    bannerSidebar: {
      imageDesktop: {
        url: data.wordpressWpGatsbyPage.acf.banner_sidebar.image_desktop.source_url,
        alt: data.wordpressWpGatsbyPage.acf.banner_sidebar.image_desktop.alt_text,
      },
      imageMobile: {
        url: data.wordpressWpGatsbyPage.acf.banner_sidebar.image_mobile.source_url,
        alt: data.wordpressWpGatsbyPage.acf.banner_sidebar.image_mobile.alt_text,
      },
      active: data.wordpressWpGatsbyPage.acf.banner_sidebar.active,
      link: data.wordpressWpGatsbyPage.acf.banner_sidebar.link,
    },
    settings: data.settings,
  }

  const seoWordpress = data.wordpressWpGatsbyPage.acf

  return (
    <Layout pageContext={pageContext} location={location}>
      <SEO
        title={`${seoWordpress.title} | ${productsData.settings.brand_name}`}
        description={seoWordpress.description}
        canonical={seoWordpress.canonical}
      />
      <ProductProvider productState={{ productsData }}>
        <Products hasAlert />
      </ProductProvider>
    </Layout>
  )
}

VaccinePage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
}

export default VaccinePage

export const pageQuery = graphql`
  query {
    wordpressWpGatsbyPage(slug: { eq: "vacinas" }) {
      acf {
        subtitle
        demonstrative_text
        banner_sidebar {
          image_desktop {
            source_url
            alt_text
          }
          image_mobile {
            source_url
            alt_text
          }
          active
          link
        }
        top_searchs
        title
        top_searchs_title
        description
        canonical
        product_budget
        show_vaccination
        vaccination_title
        vaccination_info
      }
    }
    settings: wordpressAltaThemeSettings {
      brand_name
      brand_url
      phone_df
      phone_rj
      phone_sp
      scheduling_link_df
      scheduling_link_rj
      scheduling_link_sp
      home_sched_link_rj
      home_sched_link_sp
    }
    allProducts(filter: { type: { eq: "vacina" } }, sort: { fields: name }) {
      nodes {
        slug
        nome: name
        sinonimo: synonymies
      }
    }
  }
`
